import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout';
import Navbar from '../components/Navbar/Navbar';
import { Container, List, ListItem, ListItemText } from '@material-ui/core';
import Link from 'gatsby-link';
import JsxSeo from '../components/SEO/JsxSEO';

function Sevice({ data, location }) {
  const services = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <JsxSeo title="Services" location={location} />
      <Navbar />
      <Container>
        <List>
          {services.map((service, index) => (
            <ListItem component={Link} to={`/services${service.node.fields.slug}`} key={index}>
              <ListItemText primary={service.node.frontmatter.title} />
            </ListItem>
          ))}
        </List>
      </Container>
    </Layout>
  );
}

export default Sevice;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ServiceQuery {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(services)/" } }) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
